<router-outlet></router-outlet>

@if (!loader.loaded) {
  <div class="preloader">
    <img width="120px" class="img" src="/assets/img/logo.png" alt="">
  </div>
}



